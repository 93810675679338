var body = $('body');

var roofType = $("#calculatorform-roof_type");

body.on('click', "#calculatorform-roof_type label", function() {
    $("#calculatorform-roof_type").find('label').each(function(i,k) {
        $(k).removeClass('active');
    });
    $(this).addClass('active');
});

// roofType.find('label').find('input[checked]').parent('label').addClass('active');
var form = $("#solarpanel-form");

var orientationInput = $("#calculatorform-roof_orientation");

body.on('afterValidate', "#solarpanel-form", function(event, messages, errorAttributes) {
    if(!errorAttributes.length && currentScenario !== 'location-and-consume') {
        $("#solarpanel-form-button").trigger('click');
    }
});


body.on('change', "#calculatorform-roof_orientation", function() {
    var orientation = $(this).val();
    var pos = getOrientationPixel(orientation);
    rulerJumpToPosition(pos);
    body.attr('data-orientation', pos);
});

body.on('pjax:end', function(e) {
    if(e.target.id == 'solarpanel-pjax') {
        var orientationPx = body.attr('data-orientation');
        var pitchPx = body.attr('data-pitch');
        var heightFix = body.attr('heightfix');

        var actualImg = $(".solarpanel-pitch");
        var actualImgContainer = actualImg.parent('.solarpanel-step-item-pitch-images');
        if(typeof orientationPx !== 'undefined') {
            rulerJumpToPosition(orientationPx);
        }
        if(typeof pitchPx !== 'undefined') {
            pitchJumpToPosition(pitchPx);
        }
        if(typeof heightFix !== 'undefined') {
            actualImgContainer.css('height', heightFix);
        } else {
            if($(window).width() < 390) {
                var actualWidth = ($(window).width() * 0.95) - 40;
                var divider = actualWidth / 330;
                //2610px a kép alapból és 330 széles
                var height = (actualWidth * 2610) / divider;
                var actualHeight = actualImgContainer.height();
                var neededHeight = actualHeight * divider;
                if(typeof actualHeight !== 'undefined') {
                    actualImgContainer.css('height', neededHeight+"px");
                    body.attr('heightfixed', 1);
                }
            }
        }

        console.log(currentScenario, 'currentScenario');
        if(currentScenario != 'location-and-consume') {
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#_"+currentScenario).offset().top
            }, 500)
        }
    }
});

var pitchInput = $("#calculatorform-roof_pitch");

body.on('change', "#calculatorform-roof_pitch", function() {
    var pitch = $(this).val();
    var pos = getPitchPixel(pitch);
    pitchJumpToPosition(pos);
    body.attr('data-pitch', pos);
});
$(document).ready(function() {

    if(orientationInput.length && (orientationInput.val().length > 0)) {
        rulerJumpToPosition(getOrientationPixel(orientationInput.val()));
    }
    if(pitchInput.length && (pitchInput.val().length > 0)) {
        pitchJumpToPosition(getPitchPixel(pitchInput.val()));
    }
});

function getOrientationPixel(orientation) {
    var rulerPosition = null;
    var width = $(window).width();
    if(width < 768) {
        var orientationWidth = $(".solarpanel-step-item-orientation-images").width();
        var pos = null;
        switch(orientation) {
            case 'east':
                pos = 735;
                break;
            case 'south-east':
                pos = 616;
                break;
            case 'south':
                pos = 499;
                break;
            case 'south-west':
                pos = 382;
                break;
            case 'west':
                pos = 265;
                break;
        }
        rulerPosition = -(pos - (orientationWidth / 2));
    } else {
        switch(orientation) {
            case 'east':
                rulerPosition = -550;
                break;
            case 'south-east':
                rulerPosition = -432;
                break;
            case 'south':
                rulerPosition = -315;
                break;
            case 'south-west':
                rulerPosition = -199;
                break;
            case 'west':
                rulerPosition = -80;
                break;
        }
        if(width < 1389) {
            rulerPosition = rulerPosition - 40;
        }
        if(width < 991 && width > 768) {
            rulerPosition = rulerPosition + 31;
        }
    }
    return rulerPosition + "px";
}

function rulerJumpToPosition(px) {
    var ruler = $(".orientation-ruler");
    ruler.css("margin-left", px);
}

function getPitchPixel(pitch) {
    var pitchPosition = null;
    var width = $(window).width();

    var actualImg = $(".solarpanel-pitch");

    var actualImgContainer = actualImg.parent('.solarpanel-step-item-pitch-images');
    var dividerValue = null;
    var imgWidth = 330;

    var actualImgWidth = actualImg.width();
    var actualImgContainerHeight = actualImgContainer.height();

    dividerValue = (imgWidth / actualImgWidth);
    var fixedImgContainerHeight = actualImgContainerHeight / dividerValue;
    if(width < 390 && typeof body.attr('heightfixed') == 'undefined') {
        actualImgContainer.css('height', fixedImgContainerHeight+"px");
        body.attr('heightfix', fixedImgContainerHeight+"px");
        body.attr('heightfixed', 1);
    }

    switch(pitch) {
        case '5':
            pitchPosition = 0;
            break;
        case '10':
            pitchPosition = -261;
            if(width < 1389 && 991 < width) {
                pitchPosition = -248;
            }
            break;
        case '15':
            pitchPosition = -522;
            if(width < 1389 && 991 < width) {
                pitchPosition = -496;
            }
            break;
        case '20':
            pitchPosition = -783;
            if(width < 1389 && 991 < width) {
                pitchPosition = -743;
            }
            break;
        case '25':
            pitchPosition = -1044;
            if(width < 1389 && 991 < width) {
                pitchPosition = -991;
            }
            break;
        case '30':
            pitchPosition = -1305;
            if(width < 1389 && 991 < width) {
                pitchPosition = -1238;
            }
            break;
        case '35':
            pitchPosition = -1566;
            if(width < 1389 && 991 < width) {
                pitchPosition = -1486;
            }
            break;
        case '40':
            pitchPosition = -1827;
            if(width < 1389 && 991 < width) {
                pitchPosition = -1734;
            }
            break;
        case '45':
            pitchPosition = -2088;
            if(width < 1389 && 991 < width) {
                pitchPosition = -1981;
            }
            break;
        case '50':
            pitchPosition = -2349;
            if(width < 1389 && 991 < width) {
                pitchPosition = -2229;
            }
            break;
    }

    if(dividerValue) {
        pitchPosition = pitchPosition / dividerValue;
    }

    return pitchPosition + "px";
}

function pitchJumpToPosition(px) {
    var ruler = $(".solarpanel-pitch");
    ruler.css("margin-top", px);
}